export const SET_LAYOUT_CONFIG = 'setLayoutConfig';
export const OVERRIDE_LAYOUT_CONFIG = 'overrideLayoutConfig';
export default {
  [SET_LAYOUT_CONFIG](state, payload) {
    state.commit(SET_LAYOUT_CONFIG, payload);
  },
  [OVERRIDE_LAYOUT_CONFIG](state) {
    state.commit(OVERRIDE_LAYOUT_CONFIG);
  }
};
