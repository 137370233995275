import getters from '@/store/modules/baseconfig/getters';
import actions from '@/store/modules/baseconfig/actions';
import mutations from '@/store/modules/baseconfig/mutations';

export const state = {
  baseconfig: {}
};

export default {
  state,
  getters,
  actions,
  mutations
};
