import { addRoute } from '@/router/helper';

const UploadInvoiceList = addRoute({
  path: 'list',
  name: 'upload-invoice-list',
  view: 'theme/Middle',
  redirect: { name: 'upload-invoice-list-index' },
  children: [
    addRoute({
      path: '',
      name: 'upload-invoice-list-index',
      i18n: 'menu.upload_invoice.list.title',
      view: 'pages/uploadInvoice/List'
    })
  ]
});

const UploadInvoiceDetail = addRoute({
  path: 'detail',
  name: 'upload-invoice-detail',
  view: 'theme/Middle',
  redirect: { name: 'upload-invoice-detail-index' },
  children: [
    addRoute({
      path: ':id',
      name: 'upload-invoice-detail-index',
      i18n: 'menu.upload_invoice.detail.title',
      view: 'pages/uploadInvoice/Detail'
    })
  ]
});

const UploadInvoice = addRoute({
  path: '/upload-purchase-invoice',
  i18n: 'menu.upload_invoice.title',
  view: 'theme/Middle',
  redirect: { name: 'upload-invoice-list' },
  children: [UploadInvoiceList, UploadInvoiceDetail]
});

export default UploadInvoice;
