import { SET_CLASSNAME_BY_POSITION } from '@/store/modules/htmlclass/mutations';

export const ADD_BODY_CLASSNAME = 'addBodyClassName';
export const REMOVE_BODY_CLASSNAME = 'removeBodyClassName';
export const ADD_CLASSNAME = 'addClassName';

export default {
  [ADD_BODY_CLASSNAME](context, className) {
    document.body.classList.add(className);
  },
  [REMOVE_BODY_CLASSNAME](context, className) {
    document.body.classList.remove(className);
  },
  [ADD_CLASSNAME](context, payload) {
    context.commit(SET_CLASSNAME_BY_POSITION, payload);
  }
};
