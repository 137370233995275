import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import JwtService from '@/common/services/jwt';
import Qs from 'qs';
import { API_URL } from '@/common/config/config';
import store from '@/store';
import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';

const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = API_URL;
    Vue.axios.interceptors.request.use(this.interceptorRequest);
    // 拦截返回的数据, 只要code不是200的都报错
    Vue.axios.interceptors.response.use(
      this.interceptorResponse,
      this.interceptorResponseError
    );
  },
  setHeader() {
    Vue.axios.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${JwtService.getToken()}`;
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch(error => {
      console.log(error.data.message);
      if (error.data.message == 'Unauthenticated' && error.data.code == 50001) {
        store.dispatch(PROMPT_STATUS, {
          dismissCountDown: 0, //初始提示作用于定时器
          alert_variant: 'Expired', // 提示状态 success=>成功 danger=> 报错
          alertText: ''
        });
        throw error;
      } else {
        throw error;
      }
    });
  },
  queryPDF(resource, params) {
    return Vue.axios({
      method: 'get',
      url: resource,
      params,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        withCredentials: true
      },
      responseType: 'blob'
    }).catch(function(error) {
      console.log(error);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = '') {
    return Vue.axios.get(`${resource}/${slug}`).catch(error => {
      // console.log(error);
      throw new Error(`ApiService ${error}`);
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params).catch(error => {
      // console.log(error);
      console.log(error.data.message);
      if (error.data.message == 'Unauthenticated' && error.data.code == 50001) {
        store.dispatch(PROMPT_STATUS, {
          dismissCountDown: 0, //初始提示作用于定时器
          alert_variant: 'Expired', // 提示状态 success=>成功 danger=> 报错
          alertText: ''
        });
        throw error;
      } else {
        throw error;
      }
    });
  },

  postUpload(resource, params) {
    return Vue.axios.post(`${resource}`, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      },
      //这部分非常重要，否则formdata会被转格式
      transformRequest: [
        function() {
          return params;
        }
      ]
    });
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(resource).catch(error => {
      // console.log(error);
      throw new Error(`ApiService ${error}`);
    });
  },
  interceptorResponse(response) {
    if (response.data.code === 200) {
      return Promise.resolve(response);
    }
    return Promise.reject(response);
  },
  interceptorResponseError(error) {
    return Promise.reject(error);
  },
  interceptorRequest(config) {
    if (config.method === 'get') {
      // 只对get|query请求处理
      config.paramsSerializer = params => {
        // 对数组参数进行转换, 跳过null的处理，如果是null, 则不会加入到url的序列化中
        return Qs.stringify(params, { skipNulls: true });
      };
    }
    return config;
  },
  interceptorRequestError(error) {
    return Promise.reject(error);
  },
  postFile(resource, formData) {
    return Vue.axios.post(`${resource}`, formData, {
      //这部分非常重要，否则formdata会被转格式
      transformRequest: [
        function() {
          return formData;
        }
      ]
    });
  }
};

export default ApiService;
