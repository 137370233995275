import { addRoute } from '@/router/helper';

const PurchaseOverviewList = addRoute({
  path: 'list',
  name: 'purchase-overview-list',
  view: 'theme/Middle',
  redirect: { name: 'purchase-overview-list-index' },
  children: [
    addRoute({
      path: '',
      name: 'purchase-overview-list-index',
      i18n: 'menu.purchase_tracking.list.title',
      view: 'pages/purchaseOverview/List'
    })
  ]
});

const Detail = addRoute({
  path: 'detail',
  name: 'purchase-overview-detail',
  view: 'theme/Middle',
  redirect: { name: 'purchase-overview-detail-index' },
  children: [
    addRoute({
      path: ':purchaseId',
      name: 'purchase-overview-detail-index',
      i18n: 'menu.purchase_tracking.detail.title',
      view: 'pages/purchaseOverview/Detail'
    })
  ]
});

const PurchaseOverview = addRoute({
  path: '/purchase-tracking',
  i18n: 'menu.purchase_tracking.title',
  view: 'theme/Middle',
  redirect: { name: 'purchase-overview-list' },
  children: [PurchaseOverviewList, Detail]
});

export default PurchaseOverview;
