export default {
  tabs: {
    overview: 'Overview',
    need_commercial_approval: 'Need Commercial Approval',
    need_bd_leader_approval: 'Need BD Leader Approval'
  },
  buttons: {
    records_per_page: 'Records per Page',
    add: 'Add',
    export_to_excel: 'Export to Excel',
    bulk_approve: 'Bulk Approve'
  },
  fields: {
    bd_name: 'BD Name',
    opportunity_name: 'Opportunity Name',
    country: 'Country',
    opportunity_type: 'Opportunity Type',
    opportunity_classification: 'Opportunity Classification',
    pos: 'POS',
    contract_type: 'Contract Type',
    days_since_start: 'Days Since Start',
    stage: 'Stage',
    projected_annual_gmv: 'Projected Annual GMV',
    atv: 'ATV'
  },
  stage_names: {
    10: 'Prospecting',
    20: 'Qualification',
    30: 'Value Proposition',
    40: 'Contact Decision Maker',
    50: 'First Official Appointment',
    60: 'Negotiation Review',
    70: 'Contract Out',
    80: 'Wait For Signature'
  },
  contract_type_names: {
    110: 'Commission',
    210: 'Tax Refund'
  }
};
