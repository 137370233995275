import ApiService from '@/common/services/api';
export default {
  purchaseInvoiceList(params) {
    let url = '/api/fulfillment/get-fulfillment-order-invoice-list';
    return ApiService.query(url, { params });
  },
  getPurchaseInvoiceDetail(params) {
    let url = '/api/fulfillment/get-fulfillment-order-invoice-detail';
    return ApiService.query(url, { params });
  },
  savePurchaseInvoiceRefundable(params) {
    let url = '/api/purchase/save-purchase-invoice-refundable';
    return ApiService.post(url, params);
  },
  savePurchaseInvoiceDetail(params) {
    let url = '/api/fulfillment/save-fulfillment-order-invoice-detail';
    return ApiService.post(url, params);
  },
  getPurchaseInvoiceLog(params) {
    let url = '/api/fulfillment/get-fulfillment-order-invoice-log';
    return ApiService.query(url, { params });
  }
};
