import global from '@/common/apiBus/global';
import shuttleAuth from '@/common/apiBus/shuttleAuth';
import purchase from '@/common/apiBus/purchase';
import supplier from '@/common/apiBus/supplier';
import warehouse from '@/common/apiBus/warehouse';
import articleReview from '@/common/apiBus/articleReview';
import purchaseInvoice from '@/common/apiBus/purchaseInvoice';
import uploadInvoice from '@/common/apiBus/uploadInvoice';
import saasPuchaseInvoiceFile from '@/common/apiBus/saasPuchaseInvoiceFile';
import saasPuchaseInvoice from '@/common/apiBus/saasPuchaseInvoice';

const apiBus = {
  global,
  shuttleAuth,
  purchase,
  supplier,
  warehouse,
  articleReview,
  purchaseInvoice,
  uploadInvoice,
  saasPuchaseInvoiceFile,
  saasPuchaseInvoice
};

export default apiBus;
