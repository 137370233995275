/*
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-06-20 19:39:27
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-03-30 18:11:38
 * @FilePath: /frontend/src/common/apiBus/purchaseInvoice.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import ApiService from '@/common/services/api';
export default {
  purchaseInvoiceList(params) {
    let url = '/api/purchase/purchase-overview-invoice-list';
    return ApiService.query(url, { params });
  },
  getPurchaseInvoiceDetail(params) {
    let url = '/api/purchase/get-purchase-invoice-detail';
    return ApiService.query(url, { params });
  },
  savePurchaseInvoiceRefundable(params) {
    let url = '/api/purchase/save-purchase-invoice-refundable';
    return ApiService.post(url, params);
  },
  savePurchaseInvoiceDetail(params) {
    let url = '/api/purchase/save-purchase-invoice-detail';
    return ApiService.post(url, params);
  },
  updatePurchaseInvoice(params) {
    let url = '/api/purchase/update-purchase-invoice';
    return ApiService.post(url, params);
  },
  updatePurchaseInvoiceNote(params) {
    let url = '/api/purchase/update-purchase-overview-note';
    return ApiService.post(url, params);
  },
  getPurchaseInvoiceLog(params) {
    let url = '/api/purchase/get-purchase-invoice-log';
    return ApiService.query(url, { params });
  }
};
