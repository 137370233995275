import getters from '@/store/modules/statusprompt/getters';
import actions from '@/store/modules/statusprompt/actions';
import mutations from '@/store/modules/statusprompt/mutations';

export const state = {
  status: {
    dismissCountDown: 0, //初始提示作用于定时器
    alert_variant: '', // 提示状态 success=>成功 danger=> 报错
    alertText: '' // 提示内容
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
