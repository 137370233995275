import Vue from 'vue';
import { MOCK_ENABLED } from '@/common/config/config';

var MockAdapter = require('axios-mock-adapter');

const users = [
  {
    username: 'admin',
    password: 'a1234567',
    token: 'mgfi5juf74j'
  },
  {
    username: 'guest',
    password: 'a1234567',
    token: 'fgj8fjdfk43'
  }
];

const MockService = {
  init() {
    if (MOCK_ENABLED === 'false') {
      return;
    }

    var mock = new MockAdapter(Vue.axios);
    // mock login request
    mock.onPost('/login').reply(data => {
      const credential = JSON.parse(data.data);
      const found = users.find(user => {
        return (
          credential.username === user.username &&
          credential.password === user.password
        );
      });
      if (found) {
        return [
          200,
          {
            code: 200,
            data: found
          }
        ];
      }
      return [404, { code: 201, message: 'login.auth_error' }];
    });

    // mock to verify authentication
    mock.onGet(/\/verify\/?/).reply(data => {
      const token = data.headers.Authorization.replace('Token ', '');
      if (token !== 'undefined') {
        const found = users.find(user => {
          return token === user.token;
        });

        if (found === undefined) {
          return [401, { code: 201, message: 'Invalid authentication' }];
        }

        return [
          200,
          {
            code: 200,
            data: found
          }
        ];
      }
      return [401, { code: 201, message: 'Invalid authentication' }];
    });
  }
};

export default MockService;
