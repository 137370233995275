import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import store from '@/store';
import { VERIFY_AUTH } from '@/store/modules/auth/actions';
import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';
import NProgress from 'nprogress';

NProgress.configure({
  easing: 'ease', // 动画方式
  speed: 2000, // 递增进度条的速度
  showSpinner: true, // 是否显示加载ico
  trickleSpeed: 800, // 自动递增间隔
  minimum: 0.3 // 初始化时的最小百分比
});

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  console.log('router:', to, from);
  NProgress.start();
  Promise.all([store.dispatch(VERIFY_AUTH, { path: to.path })])
    .then(() => {
      next();
      NProgress.done();
    })
    .catch(err => {
      // 不提交！！！
      NProgress.done();
      console.log('router catch:', err);
      if (to.name !== 'login-from-sign') {
        if (from.name) {
          next(false);
          store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 0, //初始提示作用于定时器
            alert_variant: 'Expired', // 提示状态 success=>成功 danger=> 报错
            alertText: ''
          });
        } else {
          next({ name: 'login-from-sign' });
        }
      } else {
        next();
      }
    });

  // 滚动到每个页面的顶部
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
