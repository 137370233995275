function addCache(id, name, item) {
  item.uid = id;
  var itemData = {};
  itemData[name] = item;
  localStorage.setItem('cache', JSON.stringify(itemData));
}
function ifCache(name) {
  if (
    window.localStorage.getItem('cache') &&
    JSON.parse(window.localStorage.getItem('cache'))[name]
  ) {
    return true;
  } else {
    return false;
  }
}
function renewCache() {
  localStorage.removeItem('cache');
}
function getCache(id, name) {
  let getC = JSON.parse(window.localStorage.getItem('cache'))[name];
  if (getC.uid == id) {
    delete getC.uid;
  } else {
    getC = null;
  }
  return getC;
}
function addVisible(name, visible) {
  let data = {};
  data[name] = visible;
  localStorage.setItem('visible', JSON.stringify(data));
}
function getVisible(name) {
  let visibleC = false;
  if (window.localStorage.getItem('visible'))
    visibleC = JSON.parse(window.localStorage.getItem('visible'))[name];
  return visibleC;
}
export default {
  addCache,
  ifCache,
  renewCache,
  getCache,
  addVisible,
  getVisible
};
