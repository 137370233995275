/*
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-06-20 19:39:27
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2022-08-29 18:50:18
 * @FilePath: /frontend/src/common/apiBus/uploadInvoice.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import ApiService from '@/common/services/api';
export default {
  purchaseInvoiceList(params) {
    let url = '/api/purchase/purchase-invoice-list';
    return ApiService.query(url, { params });
  },
  importPurchaseInvoice(params) {
    let url = '/api/purchase/import-invoice-report';
    return ApiService.post(url, params);
  },
  uploadPurchaseInvoice(params) {
    let url = '/api/purchase/upload-purchase-invoice';
    return ApiService.post(url, params);
  },
  purchaseInvoiceSupplierList(params) {
    let url = '/api/purchase/purchase-invoice-supplier-list';
    return ApiService.query(url, { params });
  },
  getUploadPurchaseInvoiceFileInfo(params) {
    let url = '/api/purchase/get-upload-purchase-invoice-file-info';
    return ApiService.query(url, { params });
  },
  addUploadPurchaseInvoice(params) {
    let url = '/api/purchase/add-upload-purchase-invoice';
    return ApiService.post(url, params);
  },
  saveUploadPurchaseInvoiceDetail(params) {
    let url = '/api/purchase/save-upload-purchase-invoice-detail';
    return ApiService.post(url, params);
  },
  getPurchaseInvoiceFileLog(params) {
    let url = '/api/purchase/get-purchase-invoice-file-log';
    return ApiService.query(url, { params });
  },
  getRelatePurchaseOverView(params) {
    let url = '/api/purchase/get-relate-purchase-over-view';
    return ApiService.query(url, { params });
  },
  getSelectRelatePurchaseOverViewList(params) {
    let url = '/api/purchase/get-select-relate-purchase-over-view-list';
    return ApiService.query(url, { params });
  },
  getRelatePurchaseOverViewLogList(params) {
    let url = '/api/purchase/get-relate-purchase-over-view-log-list';
    return ApiService.query(url, { params });
  },
  relatePurchaseOverView(params) {
    let url = '/api/purchase/relate-purchase-over-view';
    return ApiService.post(url, params);
  },
  deletePurchaseInvoiceFile(params) {
    let url = '/api/purchase/delete-purchase-invoice-file';
    return ApiService.post(url, params);
  },
  getPurchaseInvoiceInfo(params) {
    let url = '/api/purchase/get-purchase-invoice-info';
    return ApiService.query(url, { params });
  },
  updatePurchaseInvoicePaymentDate(params) {
    let url = '/api/purchase/update-purchase-invoice-file-payment-date';
    return ApiService.post(url, params);
  },
  uploadPurchaseSingleInvoiceFile(params) {
    let url = '/api/purchase/upload-purchase-single-invoice';
    return ApiService.post(url, params);
  },
  deletePurchaseSingleInvoiceFile(params) {
    let url = '/api/purchase/delete-upload-purchase-single-invoice';
    return ApiService.post(url, params);
  }
};
