export const SET_LAYOUT_CONFIG = 'setLayoutConfig';
export const OVERRIDE_LAYOUT_CONFIG = 'overrideLayoutConfig';
export const SET_MENU = 'setMenu';
export const SET_MENU_PAGES = 'setMenuPages';
export default {
  [SET_LAYOUT_CONFIG](state, payload) {
    state.config = payload;
  },
  [OVERRIDE_LAYOUT_CONFIG](state) {
    state.config = Object.assign(
      {},
      state.config,
      localStorage.getItem('config')
    );
  },
  [SET_MENU](state, payload) {
    state.sideMenu = payload;
  },
  [SET_MENU_PAGES](state, payload) {
    state.menuPages = payload;
  }
};
