export const addRoute = args => {
  const {
    path,
    view,
    name,
    i18n,
    redirect,
    icon,
    perms,
    meta,
    children
  } = args;
  const useMeta = {
    i18n,
    perms,
    icon
  };
  Object.assign(useMeta, meta);
  const route = {
    path,
    name,
    redirect,
    meta: useMeta
  };
  if (view !== null && view !== '') {
    route.component = () => import(`@/views/${view}.vue`);
  }

  if (children !== undefined) {
    route.children = children;
  }

  return route;
};
