export const getMenuPagesFromAccessMenu = accessMenu => {
  let pages = deepMenuItems(accessMenu);
  // 让page长度从大到小排列很重要. 不然无法获取当前页面的权限
  return pages.sort((a, b) => (a.length < b.length ? 1 : -1));
};

const deepMenuItems = items => {
  let arr = [];
  items.forEach(item => {
    if (item.page !== undefined) {
      arr.push('/' + item.page + '/');
    }
    if (item.submenu !== undefined && Array.isArray(item.submenu)) {
      arr = arr.concat(deepMenuItems(item.submenu));
    }
  });
  return arr;
};

export const findMatchPage = (page, pages) => {
  return pages.find(element => page + '/'.indexOf(element) === 0);
};
