import ApiService from '@/common/services/api';
export default {
  purchaseInvoiceList(params) {
    let url = '/api/purchase/purchase-invoice-list';
    return ApiService.query(url, { params });
  },
  importPurchaseInvoice(params) {
    let url = '/api/purchase/import-invoice-report';
    return ApiService.post(url, params);
  },
  uploadPurchaseInvoice(params) {
    let url = '/api/purchase/upload-purchase-invoice';
    return ApiService.post(url, params);
  },
  purchaseInvoiceSupplierList(params) {
    let url = '/api/fulfillment/get-fulfillment-order-item-list';
    return ApiService.query(url, { params });
  },
  getUploadPurchaseInvoiceFileInfo(params) {
    let url = '/api/fulfillment/get-fulfillment-order-upload-invoice-file-info';
    return ApiService.query(url, { params });
  },
  saveUploadPurchaseInvoiceDetail(params) {
    let url = '/api/fulfillment/save-fulfillment-order-upload-invoice';
    return ApiService.post(url, params);
  },
  getPurchaseInvoiceFileLog(params) {
    let url = '/api/purchase/get-purchase-invoice-file-log';
    return ApiService.query(url, { params });
  },
  getRelatePurchaseOverView(params) {
    let url = '/api/purchase/get-relate-purchase-over-view';
    return ApiService.query(url, { params });
  },
  getSelectRelatePurchaseOverViewList(params) {
    let url = '/api/purchase/get-select-relate-purchase-over-view-list';
    return ApiService.query(url, { params });
  },
  getRelatePurchaseOverViewLogList(params) {
    let url = '/api/purchase/get-relate-purchase-over-view-log-list';
    return ApiService.query(url, { params });
  },
  relatePurchaseOverView(params) {
    let url = '/api/purchase/relate-purchase-over-view';
    return ApiService.post(url, params);
  },
  deletePurchaseInvoiceFile(params) {
    let url = '/api/purchase/delete-purchase-invoice-file';
    return ApiService.post(url, params);
  },
  getPurchaseInvoiceInfo(params) {
    let url = '/api/purchase/get-purchase-invoice-info';
    return ApiService.query(url, { params });
  }
};
