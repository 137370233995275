import { addRoute } from '@/router/helper';

const UploadInvoiceList = addRoute({
  path: 'list',
  name: 'saas-purchase-invoice-file-list',
  view: 'theme/Middle',
  redirect: { name: 'saas-purchase-invoice-file-list-index' },
  children: [
    addRoute({
      path: '',
      name: 'saas-purchase-invoice-file-list-index',
      i18n: 'menu.saas_purchase_invoice_file.list.title',
      view: 'pages/saasPurchaseInvoiceFile/List'
    })
  ]
});

const UploadInvoiceDetail = addRoute({
  path: 'detail',
  name: 'saas-purchase-invoice-file-detail',
  view: 'theme/Middle',
  redirect: { name: 'saas-purchase-invoice-file-detail-index' },
  children: [
    addRoute({
      path: ':id',
      name: 'saas-purchase-invoice-file-detail-index',
      i18n: 'menu.saas_purchase_invoice_file.detail.title',
      view: 'pages/saasPurchaseInvoiceFile/Detail'
    })
  ]
});

const UploadInvoice = addRoute({
  path: '/saas-purchase-invoice-file',
  i18n: 'menu.saas_purchase_invoice_file.title',
  view: 'theme/Middle',
  redirect: { name: 'saas-purchase-invoice-file-list' },
  children: [UploadInvoiceList, UploadInvoiceDetail]
});

export default UploadInvoice;
