import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ApiService from '@/common/services/api';
import MockService from '@/common/mock';
import vSelect from 'vue-select';
import VueUploadImgs from 'vue-upload-imgs';
import JwtService from '@/common/services/jwt';

Vue.config.productionTip = false;
// Global 3rd party plugins
import 'bootstrap';
import 'popper.js';
import 'tooltip.js';
import 'perfect-scrollbar';

// Vue 3rd party plugins
import i18n from './common/plugins/vue-i18n';
import vuetify from './common/plugins/vuetify';
import './common/plugins/bootstrap-vue';
import './common/plugins/perfect-scrollbar';
import './common/plugins/vuelidate';
import './common/plugins/access';
import '@babel/polyfill';
import '@mdi/font/css/materialdesignicons.css';
import 'vue-select/src/scss/vue-select.scss';
import './common/directive/realImg.js';

import cache from './common/searchcache/index.js';

import formCache from './common/formCache/index.js';

import { Uploader } from 'vant';
import { ImagePreview } from 'vant';
import '@vant/touch-emulator';
import 'nprogress/nprogress.css';
import { Loading, Image } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(Uploader, ImagePreview);
Vue.use(Image);
Vue.use(Loading.directive);

Vue.prototype.$loading = Loading.service;

Vue.prototype.CACHE = cache;

ApiService.init();

MockService.init();

Vue.prototype.print = (obj, type) => {
  type = type || 'log';
  const log = JSON.parse(JSON.stringify(obj));
  console[type](log);
};
function filter(str) {
  // 特殊字符转义
  str += ''; // 隐式转换
  str = str.replace(/%/g, '%25');
  str = str.replace(/\+/g, '%2B');
  str = str.replace(/ /g, '%20');
  str = str.replace(/\//g, '%2F');
  str = str.replace(/\?/g, '%3F');
  str = str.replace(/&/g, '%26');
  str = str.replace(/#/g, '%23');
  return str;
}
/* 
  全局防抖
  t=>this对象
  fnName=>函数名称
  time=>时间
*/
Vue.prototype.$vueDebounce = (t, fnName, time) => {
  let timeout = null,
    vthis = t;
  return (() => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      vthis[fnName]();
    }, time);
  })();
};

/* 
  全局对象转url
  null 不传
  数组是逗号拼接 ！！！
*/

Vue.prototype.$formateObjToParamStr = paramObj => {
  const sdata = [];
  for (let attr in paramObj) {
    console.log(paramObj[attr]);
    if (paramObj[attr] != null) {
      sdata.push(`${attr}=${filter(paramObj[attr])}`);
    }
  }
  return sdata.join('&');
};

/* 
  把指定对象里面相同的id查找出来 返回指定length的数组
*/

Vue.prototype.$sameGroup = (filterObj, id) => {
  var filterArr = [];
  filterObj.forEach((key, index) => {
    if (key.MO_action.shipment_id == id && key.selectDisabled != 'disabled') {
      filterArr.push(index);
    }
  });
  return filterArr;
};

Vue.component('sv-select', vSelect);
// 表单的缓存
Vue.prototype.$formCache = formCache;

Vue.use(VueUploadImgs);

new Vue({
  router,
  store,
  i18n,
  vuetify,
  mounted() {
    // 在实例被挂载后调用, 判断用户是否登录. 没有登录只能访问Login页面
    if (!this.$store.getters.isAuthenticated) {
      if (process.env.VUE_APP_SSO === 'true') {
        let params = new URLSearchParams(location.search.slice(1));
        if (params.get('token') === null || params.get('token') === '') {
          location.href = process.env.VUE_APP_SSO_URL;
        } else {
          JwtService.saveToken(params.get('token'));
          console.log(123);
          this.$router.push({ name: 'dashboard' });
        }
      } else {
        console.log(this.$route);
        // this.$router.push({ name: 'login' });
      }
    }
  },
  render: h => h(App)
}).$mount('#app');
