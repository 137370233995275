import JwtService from '@/common/services/jwt';
import actions from './actions';
import mutations from './mutations';

export const state = {
  error: null,
  user: {},
  isAuthenticated: !!JwtService.getToken(),
  curPageAccess: []
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  getCurPageAccess(state) {
    return state.curPageAccess;
  },
  hasCurPageAccess: state => permission => {
    return state.curPageAccess.includes(permission);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
