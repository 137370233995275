import ApiService from '@/common/services/api';
export default {
  getBaseConfig() {
    return ApiService.query('/api/base/get-base-config');
  },
  purchaseTargetList(params) {
    let url = '/api/purchase/purchase-target-list';
    return ApiService.query(url, { params });
  },
  productView(params) {
    let url = '/api/purchase/ProductView';
    return ApiService.query(url, { params });
  },
  purchaseProduct(params) {
    let url = '/api/purchase/purchase-product';
    return ApiService.query(url, { params });
  },
  purchaseTargetDetail(params) {
    let url = '/api/purchase/purchase-target-detail';
    return ApiService.query(url, { params });
  },
  purchaseTargetLogs(params) {
    let url = '/api/purchase/purchase-target-logs';
    return ApiService.query(url, { params });
  },
  getSupplierList(params) {
    return ApiService.query('/api/purchase/get-supplier-list', { params });
  },
  purchaseOverviewList(params) {
    let url = '/api/purchase/purchase-overview-list';
    return ApiService.query(url, { params });
  },
  voidedPurchaseTarget(params) {
    let url = '/api/purchase/voided-purchase-target';
    return ApiService.query(url, { params });
  },
  onHoldPurchaseTarget(params) {
    let url = '/api/purchase/on-hold-purchase-target';
    return ApiService.query(url, { params });
  },
  unHoldPurchaseTarget(params) {
    let url = '/api/purchase/un-hold-purchase-target';
    return ApiService.query(url, { params });
  },
  purchaseOverviewDetail(params) {
    let url = '/api/purchase/purchase-overview-detail';
    return ApiService.query(url, { params });
  },
  purchaseOverviewLogs(params) {
    let url = '/api/purchase/purchase-overview-logs';
    return ApiService.query(url, { params });
  },
  getPurchaseInvoiceFileInfo(params) {
    let url = '/api/purchase/get-purchase-invoice-file-info';
    return ApiService.query(url, { params });
  },
  getPurchaseCommentf(params) {
    let url = '/api/purchase/get-purchase-comment';
    return ApiService.query(url, { params });
  },
  savePurchaseComment(params) {
    let url = '/api/purchase/save-purchase-comment';
    return ApiService.post(url, params);
  },
  purchaseOverviewSave(params) {
    let url = '/api/purchase/purchase-overview-save';
    return ApiService.post(url, params);
  },
  purchaseOverviewVoided(params) {
    let url = '/api/purchase/purchase-overview-voided';
    return ApiService.post(url, params);
  },
  purchaseOverviewDeliveryDone(params) {
    let url = '/api/purchase/purchase-overview-delivery-done';
    return ApiService.post(url, params);
  },
  purchaseOverviewConfirm(params) {
    let url = '/api/purchase/purchase-overview-confirm';
    return ApiService.post(url, params);
  },
  purchaseOverviewDelivery(params) {
    let url = '/api/purchase/purchase-overview-delivery';
    return ApiService.post(url, params);
  },
  getExpressList() {
    let url = '/api/base/get-express-list';
    return ApiService.query(url);
  },
  purchaseTargetSave(params) {
    let url = '/api/purchase/purchase-target-save';
    return ApiService.post(url, params);
  },
  getCountryList() {
    let url = '/api/base/get-country-list';
    return ApiService.query(url);
  },
  getSettlementList() {
    let url = '/api/base/get-settlement-list';
    return ApiService.query(url);
  },
  purchaseSummaryList(params) {
    let url = '/api/purchase/purchase-summary-list';
    return ApiService.query(url, { params });
  },
  purchaseSummaryDetail(params) {
    let url = '/api/purchase/purchase-summary-detail';
    return ApiService.query(url, { params });
  },
  purchaseSummarySupplierDetail(params) {
    let url = '/api/purchase/purchase-summary-supplier-detail';
    return ApiService.query(url, { params });
  },
  purchaseOverviewDeliveryReception(params) {
    let url = '/api/purchase/purchase-overview-delivery-reception';
    return ApiService.post(url, params);
  },
  deletePurchaseInvoice(params) {
    let url = '/api/purchase/delete-purchase-invoice';
    return ApiService.post(url, params);
  },
  uploadPurchaseOverviewInvoice(params) {
    let url = '/api/purchase/upload-purchase-overview-invoice';
    return ApiService.postUpload(url, params);
  },
  deleteOverviewPurchaseInvoice(params) {
    let url = '/api/purchase/delete-overview-purchase-invoice';
    return ApiService.post(url, params);
  },
  savePurchaseInvoiceFile(params) {
    let url = '/api/purchase/save-purchase-invoice-file';
    return ApiService.post(url, params);
  },
  getChannelList(params) {
    let url = '/api/purchase/get-channel-list';
    return ApiService.query(url, { params });
  },
  purchaseOverviewConfirmMass(params) {
    let url = '/api/purchase/purchase-overview-confirm-mass';
    return ApiService.post(url, params);
  },
  purchaseOverviewDeliveryMass(params) {
    let url = '/api/purchase/purchase-overview-delivery-mass';
    return ApiService.post(url, params);
  }

};
