export default {
  advanced_search: 'Advanced Search',
  route: 'Route',
  title: 'Title',
  id: 'ID',
  position: 'Position',
  parent_menu: 'Parent Menu',
  action: 'Action',
  sorting: 'Sorting',
  status: 'Status',
  root_menu: 'Root Menu',
  add: 'Add',
  edit: 'Information',
  advanced: 'Advanced',
  menu_type: 'Menu Type',
  filter: 'Filter',
  reset: 'Reset',
  loading: 'Loading',
  please_choose: 'Please select',
  please_choose_input: 'Please Input',
  please_choose_textarea: 'Please Textarea',
  product_category: 'Product Category',
  brand_name: 'Brand Name',
  merchant_country: 'Merchant Country',
  company_name: 'Company Name',
  target_country: 'Target Country',
  channel_name: 'Channel Name',
  merchant_name: 'POS Name',
  no_picture: 'No picture',
  no_data: 'No data',
  no_description: 'No description',
  no_time: 'No time',
  release_date: 'Release date:',
  sku_name: 'SKU Name',
  product_photos: 'Product Photos',
  product_description: 'Product Description',
  product_attributes: 'Product Attributes',
  color_name: 'Color Name',
  capacity: 'Capacity',
  size: 'Size',
  net_weight: 'Net Weight',
  gross_weight: 'Gross Weight',
  package_dimension: 'Package Dimension',
  sku_id: 'SKU ID:',
  product_status: 'Product Status',
  salable: 'Salable',
  visible: 'Visible',
  pos_price: 'POS Price (no discount, incl VAT)',
  tax_class: 'Tax Class',
  special_price: 'Special Price (incl VAT)',
  special_price_start_date: 'Special Price Start Date',
  special_price_end_date: 'Special Price End Date',
  te_buying_price: 'TE Buying Price (no VAT)',
  te_discount: 'TE Discount',
  pos_sku_id: 'Pos Sku Id:',
  delivery_method: 'Delivery Method:',
  date_arrival: 'Date of arrival:',
  number_intentions: 'Number of intentions:',
  matching_intentions: 'Matching intentions:',
  terms_conditions: 'Terms & Conditions',
  submitText: 'Submit',
  close: 'Close',
  cancel: 'Cancel',
  Back_list: 'Back to list',
  quote: 'Quote',
  quantity_ordered: 'Quantity Ordered',
  quote_valid_till: 'Quote Valid Till',
  guaranteed_by: 'Guaranteed by',
  deposit: 'Deposit',
  deposit_kept_by: 'Deposit kept by',
  down_payment: 'Down Payment',
  down_payment_kept_by: 'Down payment kept by',
  free_cancellation_period: 'Free Cancellation Period',
  cancellation_charge: 'Cancellation Charge',
  buy_with: 'Buy With',
  out_compensation: 'Out-of-Stock Compensation',
  compensation_details: 'Compensation Details',
  delivery_option: 'Delivery Option',
  accept_bargain_for_group_buying: 'Accept Bargain for Group Buying',
  group_discount: 'Group Discount',
  moq: 'MOQ',
  discount: 'Discount',
  remarks: 'Remarks',
  required_prompt: 'Fields marked with * are required!',
  successfully: 'Saved successfully',
  leaveComments: 'Input Comments'
};
