import { addRoute } from '@/router/helper';

const PurchaseInvoiceList = addRoute({
  path: 'list',
  name: 'saas-purchase-invoice-list',
  view: 'theme/Middle',
  redirect: { name: 'saas-purchase-invoice-list-index' },
  children: [
    addRoute({
      path: '',
      name: 'saas-purchase-invoice-list-index',
      i18n: 'menu.sass_purchase_invoice.list.title',
      view: 'pages/saasPurchaseInvoice/List'
    })
  ]
});

// const Detail = addRoute({
//   path: 'detail',
//   name: 'warehouse_entrance-detail',
//   view: 'theme/Middle',
//   redirect: { name: 'warehouse-entrance-detail-index' },
//   children: [
//     addRoute({
//       path: ':warehouse_entrance_id',
//       name: 'warehouse-entrance-detail-index',
//       i18n: 'menu.warehouse_entrance.detail.title',
//       view: 'pages/warehouseEntrance/Detail'
//     })
//   ]
// });

const PurchaseInvoice = addRoute({
  path: '/saas-purchase-invoice',
  i18n: 'menu.sass_purchase_invoice.title',
  view: 'theme/Middle',
  redirect: { name: 'saas-purchase-invoice-list' },
  children: [PurchaseInvoiceList]
});

export default PurchaseInvoice;
