import Vue from 'vue';
import vuelidate from 'vuelidate';
import vuelidateErrorExtractor, { templates } from 'vuelidate-error-extractor';
import i18nAttributes from '@/common/plugins/vuelidate-i18n-attributes';
import FormGroup from '@/components/Form/FormGroup';
import CustomFormGroup from '@/components/Form/CustomFormGroup';

const validationKeys = {
  minLength: {
    // Validation rule name in vuelidate
    validationKey: 'min.string', // Validation key inside our deep messages object, Laravel shown here.
    params: [
      {
        vue: 'min', // Vuelidate param name
        ext: 'min' // Parameter inside the message
      }
    ]
  },
  sameAs: {
    validationKey: 'same',
    params: [
      {
        vue: 'eq', // Vuelidate uses `eq` for other value
        ext: 'other' // Laravel uses `other`
      }
    ]
  }
};

Vue.use(vuelidate);
Vue.use(vuelidateErrorExtractor, {
  i18n: 'validation',
  validationKeys,
  i18nAttributes
});

Vue.component('FormGroup', FormGroup);
Vue.component('CustomFormGroup', CustomFormGroup);
Vue.component('formWrapper', templates.FormWrapper);
