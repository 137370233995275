<template>
  <b-form-group
    :invalid-feedback="firstErrorMessage"
    :state="isValid"
    :label="label"
    :label-for="labelFor"
    :label-cols-sm="labelColsSm"
    :label-align-sm="labelAlignSm"
    :description="description"
  >
    <slot
      :attrs="{ state: isValid }"
      :listeners="{ input: () => preferredValidator.$touch() }"
    />
  </b-form-group>
</template>
<script>
import { singleErrorExtractorMixin } from 'vuelidate-error-extractor';

export default {
  name: 'FormGroup',
  extends: singleErrorExtractorMixin,
  props: ['labelFor', 'labelColsSm', 'labelAlignSm', 'description']
};
</script>
