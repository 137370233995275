import { addRoute } from '@/router/helper';

// const LoginPage = addRoute({
//   path: '/login',
//   view: 'pages/auth/Login',
//   name: 'login',
//   i18n: 'menu.login'
// });

const LoginFromSignPage = addRoute({
  path: '/login-from-sign',
  view: 'pages/auth/LoginFromSign',
  name: 'login-from-sign',
  i18n: 'menu.login-from.sign'
});

const AuthPages = addRoute({
  path: '/',
  view: 'pages/auth/Auth',
  // children: [LoginPage, LoginFromSignPage]
  children: [LoginFromSignPage]
});

export default AuthPages;
