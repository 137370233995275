import { addRoute } from '@/router/helper';

const WarehouseEntranceList = addRoute({
  path: 'list',
  name: 'warehouse-entrance-list',
  view: 'theme/Middle',
  redirect: { name: 'warehouse-entrance-list-index' },
  children: [
    addRoute({
      path: '',
      name: 'warehouse-entrance-list-index',
      i18n: 'menu.warehouse_entrance.list.title',
      view: 'pages/warehouseEntrance/List'
    })
  ]
});

const Detail = addRoute({
  path: 'detail',
  name: 'warehouse_entrance-detail',
  view: 'theme/Middle',
  redirect: { name: 'warehouse-entrance-detail-index' },
  children: [
    addRoute({
      path: ':warehouse_entrance_id',
      name: 'warehouse-entrance-detail-index',
      i18n: 'menu.warehouse_entrance.detail.title',
      view: 'pages/warehouseEntrance/Detail'
    })
  ]
});

const WarehouseEntrance = addRoute({
  path: '/warehouse-entrance',
  i18n: 'menu.warehouse_entrance.title',
  view: 'theme/Middle',
  redirect: { name: 'warehouse-entrance-list' },
  children: [WarehouseEntranceList, Detail]
});

export default WarehouseEntrance;
