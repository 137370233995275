export default {
  id: 'ID',
  user_id: 'User Id',
  title: 'Title',
  username: 'Username',
  password: 'Password',
  system_id: 'System Id',
  position: 'Position',
  appellation: 'Title',
  firstname: 'Firstname',
  lastname: 'Lastname',
  email_address: 'E-Mail',
  mobile: 'Mobile',
  telephone: 'Tel 1',
  telephone2: 'Tel 2',
  fax: 'Fax',
  fax2: 'Fax 2',
  address: 'Address',
  country: 'Country',
  postcode: 'Post Code',
  city: 'City',
  language: 'Language',
  auto_logout: 'Time Out',
  status: 'Status',
  access: 'Permission',
  sorting: 'Sorting',
  type: 'Type',
  target: 'Target',
  icon: 'Icon',
  route: 'Route',
  action: 'Action',
  remark: 'Remark',
  description: 'Description',
  created_at: 'Creation Date',
  bd_user: 'BD Name',
  segment: 'Opportunity Segment',
  company_id: 'Opportunity Company Name',
  opp_type: 'Opportunity Type',
  classification: 'Opportunity Classification',
  annual_gmv: 'Annual GMV',
  atv: 'ATV',
  contract_service_type: 'Service Type',
  pos_id: 'POS Name',
  name: 'Company Name',
  reason_type: 'Reason of Rejection',
  country_id: 'Country',
  billing_cycle: 'Billing Cycle',
  fixed_date: 'Fixed Date',
  gmv_type: 'GMV Type',
  total_cps_rate: 'Default Commission',
  has_special_total_cps_rate: 'Special Commission',
  refund_product: 'Tax Refund Product',
  refund_rate_type: 'Refund Rate',
  has_merchant_fee: 'Merchant Fee',
  legal_entity_name: 'Legal Entity Name',
  city_id: 'City',
  form: {
    title: 'Title',
    firstname: 'Given Name',
    lastname: 'Surname',
    contact_type: 'Type',
    mobile: 'Tel',
    email: 'Email',
    social_network: 'Social Network'
  }
};
