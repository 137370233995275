import ApiService from '@/common/services/api';
import JwtService from '@/common/services/jwt';
import { getMenuPagesFromAccessMenu } from '@/utils/menu';
import {
  PURGE_AUTH,
  SET_AUTH,
  SET_USER,
  SET_ERROR,
  SET_CUR_PAGE_ACCESS
} from '@/store/modules/auth/mutations';
import { SET_MENU, SET_MENU_PAGES } from '@/store/modules/config/mutations';
import apiBus from '@/common/apiBus';

export const LOGIN_FROM_SIGN = 'loginFromSign';
export const LOGOUT = 'logout';
export const VERIFY_AUTH = 'verifyAuth';

export default {
  [VERIFY_AUTH](context, { path, targetTecrmGroupId }) {
    return new Promise((resolve, reject) => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        apiBus.shuttleAuth
          .verify({ path, targetTecrmGroupId })
          .then(({ data }) => {
            context.commit(SET_USER, data.data.user);
            context.commit(SET_MENU, data.data.side_menu);
            const menuPages = getMenuPagesFromAccessMenu(data.data.side_menu);
            context.commit(SET_MENU_PAGES, menuPages);
            context.commit(SET_CUR_PAGE_ACCESS, data.data.cur_page_access);
            resolve(data.data);
          })
          .catch(({ response }) => {
            context.commit(PURGE_AUTH);
            if (response !== undefined) {
              context.commit(SET_ERROR, response.data.message);
            }
            reject();
          });
      } else {
        context.commit(PURGE_AUTH);
        reject();
      }
    });
  },
  [LOGIN_FROM_SIGN](context, credentials) {
    return new Promise(resolve => {
      apiBus.shuttleAuth
        .loginFromSign(credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, {
            token: data.data.access_token,
            user: data.data.user
          });
          context.commit(SET_ERROR, '');
          resolve(data.data);
        })
        .catch(error => {
          console.log(error);
          context.commit(SET_ERROR, error.data.message);
        });
    });
  },
  [LOGOUT](context) {
    return new Promise(resolve =>
      apiBus.shuttleAuth.logout().then(({ data }) => {
        if (process.env.VUE_APP_SSO === 'true') {
          if (data.data.redirect_url) {
            context.commit(PURGE_AUTH);
            location.href = data.data.redirect_url;
            return;
          }
        } else {
          context.commit(PURGE_AUTH);
        }
        resolve();
      })
    );
  }
};
