export default {
  select_default: 'Please Select',
  appellation_select_options: {
    1: 'Mr.',
    2: 'Ms.'
  },
  language_options: {
    en: 'English',
    de: 'Deutsch',
    zh: '中文'
  },
  auto_logout_options: {
    5: '5 min',
    10: '10 min',
    15: '15 min',
    30: '30 min',
    60: '1 hour',
    120: '2 hours'
  },
  position_options: {
    0: 'None',
    1: 'Menu - Main/Sidebar',
    2: 'Menu - Header',
    3: 'Menu - Footer',
    4: 'Menu - Content Tab',
    5: 'Button - Top',
    6: 'Button - Bottom',
    7: 'Button - Right'
  },
  status: {
    0: 'Activated',
    10: 'Deactivated',
    90: 'Deleted'
  },
  private: 'Private',
  public: 'Public',
  menu_module: {
    position: {
      0: 'None',
      1: 'Main',
      2: 'Top',
      3: 'Bottom',
      4: 'Tab',
      5: 'Button - Top',
      6: 'Button - Bottom',
      7: 'Button - Right'
    },
    type: {
      0: 'Internal Menu',
      1: 'External Menu',
      2: 'Placeholder',
      50: 'Permission'
    },
    target: {
      _self: '_self',
      _black: '_black',
      _parent: '_parent'
    }
  },
  root_menu: 'Root Menu'
};
