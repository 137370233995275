import ApiService from '@/common/services/api';
export default {
  warehouseEntranceList(params) {
    let url = '/api/logistic/warehouse-entrance-list';
    return ApiService.query(url, { params });
  },
  warehouseEntranceDetail(params) {
    let url = '/api/logistic/warehouse-entrance-detail';
    return ApiService.query(url, { params });
  },
  warehouseEntranceReception(params) {
    let url = '/api/logistic/warehouse-entrance-reception';
    return ApiService.post(url, params);
  },
  warehouseEntranceLogs(params) {
    let url = '/api/logistic/warehouse-entrance-logs';
    return ApiService.query(url, { params });
  },
  getInventoryList(params) {
    let url = '/api/warehouse/get-inventory-list';
    return ApiService.query(url, { params });
  },
  getInventoryWarehouseDetail(params) {
    let url = '/api/warehouse/get-inventory-warehouse-detail';
    return ApiService.query(url, { params });
  },
  addWarehouseStockInNewProductInspection(params) {
    let url = '/api/warehouse/add-warehouse-stock-in-new-product-inspection';
    return ApiService.post(url, params);
  },
  warehouseStockNotInspection(params) {
    let url = '/api/warehouse/warehouse-stock-not-inspection';
    return ApiService.post(url, params);
  },
  warehouseInventoryCodeInspection(params) {
    let url = '/api/warehouse/warehouse-inventory-code-inspection';
    return ApiService.post(url, params);
  }
};
