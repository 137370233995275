import { addRoute } from '@/router/helper';

const PurchaseTargetList = addRoute({
  path: 'list',
  name: 'purchase-target-list',
  view: 'theme/Middle',
  redirect: { name: 'purchase-target-list-index' },
  children: [
    addRoute({
      path: '',
      name: 'purchase-target-list-index',
      i18n: 'menu.purchase_target.list.title',
      view: 'pages/purchaseTarget/List'
    })
  ]
});

const PurchaseAdd = addRoute({
  path: 'add-new',
  name: 'purchase-add-new',
  view: 'theme/Middle',
  redirect: { name: 'purchase-add-new' },
  children: [
    addRoute({
      path: '',
      name: 'add-new',
      i18n: 'menu.purchase_target.add_new.title',
      view: 'pages/purchaseTarget/Add'
    })
  ]
});

const PurchaseTargetDetail = addRoute({
  path: 'detail',
  name: 'purchase-target-detail',
  view: 'theme/Middle',
  redirect: { name: 'purchase-target-detail-index' },
  children: [
    addRoute({
      path: ':purchaseId',
      name: 'purchase-target-detail-index',
      i18n: 'menu.purchase_target.detail.title',
      view: 'pages/purchaseTarget/Detail'
    })
  ]
});

const PurchaseTarget = addRoute({
  path: '/purchase-target',
  i18n: 'menu.purchase_target.title',
  view: 'theme/Middle',
  redirect: { name: 'purchase-target-list' },
  children: [PurchaseTargetList, PurchaseAdd, PurchaseTargetDetail]
});

export default PurchaseTarget;
