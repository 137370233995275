import Vue from 'vue';
import Vuex from 'vuex';
import auth from '@/store/modules/auth';
import htmlclass from '@/store/modules/htmlclass';
import breadcrumbs from '@/store/modules/breadcrumbs';
import config from '@/store/modules/config';
import statusprompt from '@/store/modules/statusprompt';
import baseconfig from '@/store/modules/baseconfig';

Vue.use(Vuex);
export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    htmlclass,
    breadcrumbs,
    config,
    statusprompt,
    baseconfig
  }
});
