import { addRoute } from '@/router/helper';

const PurchaseSummaryList = addRoute({
  path: 'list',
  name: 'purchase-summary-list',
  view: 'theme/Middle',
  redirect: { name: 'purchase-summary-list-index' },
  children: [
    addRoute({
      path: '',
      name: 'purchase-summary-list-index',
      i18n: 'menu.purchase_summary.list.title',
      view: 'pages/purchaseSummary/List'
    })
  ]
});

const Detail = addRoute({
  path: 'detail',
  name: 'purchase-summary-detail',
  view: 'theme/Middle',
  redirect: { name: 'purchase-summary-detail-index' },
  children: [
    addRoute({
      path: ':purchaseSummaryId',
      name: 'purchase-summary-detail-index',
      i18n: 'menu.purchase_summary.detail.title',
      view: 'pages/purchaseSummary/Detail'
    })
  ]
});

const PurchaseSummary = addRoute({
  path: '/purchase-summary',
  i18n: 'menu.purchase_summary.title',
  view: 'theme/Middle',
  redirect: { name: 'purchase-summary-list' },
  children: [PurchaseSummaryList, Detail]
});

export default PurchaseSummary;
