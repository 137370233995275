import { addRoute } from '@/router/helper';

const articleReviewList = addRoute({
  path: 'list',
  name: 'article-review-list',
  view: 'theme/Middle',
  redirect: { name: 'article-review-list-index' },
  children: [
    addRoute({
      path: '',
      name: 'article-review-list-index',
      i18n: 'menu.article_review.list.title',
      view: 'pages/articleReview/List'
    })
    // addRoute({
    //   path: 'detail/:id',
    //   name: 'article-review-detail-index',
    //   i18n: 'menu.article_review.detail.title',
    //   view: 'pages/articleReview/Detail'
    // })
  ]
});

const Detail = addRoute({
  path: 'detail',
  name: 'article-review-detail',
  view: 'theme/Middle',
  redirect: { name: 'article-review-detail-index' },
  children: [
    addRoute({
      path: ':article_review_id',
      name: 'article-review-detail-index',
      i18n: 'menu.article_review.detail.title',
      view: 'pages/articleReview/Detail'
    })
  ]
});

const articleReview = addRoute({
  path: '/article-review',
  i18n: 'menu.article_review.title',
  view: 'theme/Middle',
  redirect: { name: 'article-review-list' },
  children: [articleReviewList, Detail]
});

export default articleReview;
