import JwtService from '@/common/services/jwt';

export const SET_ERROR = 'setError';
export const SET_AUTH = 'setAuth';
export const PURGE_AUTH = 'purgeAuth';
export const SET_USER = 'setUser';
export const SET_CUR_PAGE_ACCESS = 'setCurPageAccess';

export default {
  [SET_ERROR](state, error) {
    state.error = error;
  },
  [SET_AUTH](state, { user, token }) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    JwtService.saveToken(token);
  },
  [SET_USER](state, user) {
    state.user = user;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  },
  [SET_CUR_PAGE_ACCESS](state, curPageAccess) {
    state.curPageAccess = curPageAccess;
  }
};
