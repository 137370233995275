import common from '@/common/config/i18n/en/common';
import login from '@/common/config/i18n/en/login';
import attributes from '@/common/config/i18n/en/attributes';
import validation from '@/common/config/i18n/en/validation';
import menu from '@/common/config/i18n/en/menu';
import help from '@/common/config/i18n/en/help';
import form from '@/common/config/i18n/en/form';
import page from '@/common/config/i18n/en/page';
import button from '@/common/config/i18n/en/button';
import opp from '@/common/config/i18n/en/opportunity';
export default {
  common,
  login,
  attributes,
  validation,
  menu,
  help,
  form,
  page,
  button,
  opp
};
