import Vue from 'vue';
import store from '@/store';
// has 指定用于template中判断权限
const has = Vue.directive('has-access', {
  inserted: (el, binding) => {
    if (!Vue.prototype.$_hasAccess(binding.value)) {
      el.parentNode.removeChild(el);
    }
  }
});

// 用于判断权限, 有些不是很好用指定判断的位置可以用这个方法判断
Vue.prototype.$_hasAccess = permission => {
  return store.getters.hasCurPageAccess(permission);
};

export { has };
