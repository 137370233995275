import ApiService from '@/common/services/api';
export default {
  articleReviewList(params) {
    let url = '/api/logistic/article-review-list';
    return ApiService.query(url, { params });
  },
  articleReviewDetail(params) {
    let url = '/api/logistic/article-review-detail';
    return ApiService.query(url, { params });
  },
  articleReviewStore(params) {
    let url = '/api/logistic/article-review-store';
    return ApiService.post(url, params);
  },
  // articleReviewStore2(formData) {
  //   let url = '/api/logistic/article-review-store';
  //   return ApiService.postFile(url, formData.formData);
  // },
  articleReviewSave(params) {
    let url = '/api/logistic/article-review-save';
    return ApiService.post(url, params);
  },
  articleReviewStoreMass(params) {
    let url = "/api/logistic/article-review-store-mass";
    return ApiService.post(url, params);
  },
};
