export default {
  welcome: 'Welcome to {system_name}!',
  desc:
    'TRAVEL EASY SOA platform for business services, internal core servcies and team collaboration',
  btn: {
    login: 'Login'
  },
  title: 'Login',
  auth_error: 'Username or password is incorrect, please re-enter.'
};
