<template>
  <b-form-group
    :invalid-feedback="firstErrorMessage"
    :state="isValid"
    :label="i18nLabel"
    :label-for="labelFor"
    :label-cols-sm="labelColsSm"
    :label-cols-lg="labelColsLg"
    :label-align-sm="labelAlignSm"
    :label-class="{ required: requiredClass }"
    :description="description"
  >
    <slot
      name="default"
      :attrs="{ state: isValid }"
      :listeners="{ input: () => preferredValidator.$touch() }"
    />
    <slot
      name="live-search"
      :attrs="{ class: liveSearchIsError ? 'error' : '' }"
      :listeners="{ input: () => preferredValidator.$touch() }"
    />
  </b-form-group>
</template>
<script>
import { singleErrorExtractorMixin } from 'vuelidate-error-extractor';

export default {
  name: 'CustomFormGroup',
  extends: singleErrorExtractorMixin,
  props: {
    label: { type: String, default: '' },
    labelFor: { type: String, default: '' },
    description: { type: String, default: '' },
    labelColsSm: { type: String, default: '4' },
    labelColsLg: { type: String, default: '3' },
    labelAlignSm: { type: String, default: 'right' }
  },
  computed: {
    i18nLabel() {
      if (this.label !== '') {
        return this.label;
      }

      if (this.name !== '') {
        return this.$t('attributes.' + this.name);
      }
      return '';
    },
    liveSearchIsError() {
      // 由于live search 是根据isError来判断，所以这里做特色处理
      return this.isValid === null ? false : !this.isValid;
    },
    requiredClass() {
      if (
        Object.prototype.hasOwnProperty.call(
          this.preferredValidator,
          'required'
        )
      ) {
        return true; // 如果有required属性，则需要加上必填的class
      }
      return false;
    }
  },
  watch: {
    hasRequired(n, o) {
      console.log(n, o);
    }
  }
};
</script>
