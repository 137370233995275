import ApiService from '@/common/services/api';

export default {
  loginFromSign(params) {
    return ApiService.query('shuttle-auth/store/login/from-sign', {
      params
    });
  },
  verify(params) {
    return ApiService.query('api/auth/verify', {
      params
    });
  },
  logout() {
    let url = 'shuttle-auth/store/logout';
    if (process.env.VUE_APP_SSO === 'true') {
      url = 'sso/logout';
    }

    return ApiService.query(url);
  }
};
