import actions from '@/store/modules/htmlclass/actions';
import mutations from '@/store/modules/htmlclass/mutations';

export const state = {
  classes: {}
};

const getters = {
  getClasses: state => position => {
    if (typeof position !== 'undefined') {
      return state.classes[position];
    }
    return state.classes;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
