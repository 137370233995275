import { addRoute } from '@/router/helper';

const ManageWarehouseList = addRoute({
  path: 'list',
  name: 'manage-warehouse-list',
  view: 'theme/Middle',
  redirect: { name: 'manage-warehouse-list-index' },
  children: [
    addRoute({
      path: '',
      name: 'manage-warehouse-list-index',
      i18n: 'menu.warehouse_inventory.list.title',
      view: 'pages/manageWarehouse/List'
    })
  ]
});

const ManageWarehouse = addRoute({
  path: '/warehouse-inventory',
  i18n: 'menu.warehouse_inventory.title',
  view: 'theme/Middle',
  redirect: { name: 'manage-warehouse-list' },
  children: [ManageWarehouseList]
});

export default ManageWarehouse;
