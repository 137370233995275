import actions from '@/store/modules/config/actions';
import mutations from '@/store/modules/config/mutations';
import objectPath from 'object-path';
import config from '@/common/config/layout.config';
import { findMatchPage } from '@/utils/menu';

const state = {
  config: config,
  sideMenu: [],
  menuPages: []
};

const getters = {
  layoutConfig: state => (path, defaultValue) => {
    return objectPath.get(state.config, path, defaultValue);
  },
  canVisitMenu: state => page => {
    return state.menuPages.includes(page);
  },
  findCurModule: state => page => {
    return findMatchPage(page, state.menuPages);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
  // promptStatus
};
