import { addRoute } from '@/router/helper';
import AuthPages from '@/router/pages/auth';
import PurchaseTarget from '@/router/pages/purchaseTarget';
import PurchaseOverview from '@/router/pages/purchaseOverview';
import WarehouseEntrance from '@/router/pages/warehouseEntrance';
import ArticleReview from '@/router/pages/articleReview';
import PurchaseSummary from '@/router/pages/purchaseSummary';
import PurchaseInvoice from '@/router/pages/purchaseInvoice';
import SaaSPuchaseInvoice from '@/router/pages/saasPurchaseInvoice';
import SaaSPuchaseInvoiceFile from '@/router/pages/saasPurchaseInvoiceFile';
import UploadInvoice from '@/router/pages/uploadInvoice';
import ManageWarehouse from '@/router/pages/manageWarehouse';

export default [
  addRoute({
    path: '/',
    redirect: '',
    view: 'theme/Base',
    children: [
      PurchaseTarget,
      PurchaseOverview,
      WarehouseEntrance,
      ArticleReview,
      PurchaseSummary,
      PurchaseInvoice,
      SaaSPuchaseInvoice,
      SaaSPuchaseInvoiceFile,
      UploadInvoice,
      ManageWarehouse
    ]
  }),
  AuthPages
];
