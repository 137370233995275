export default {
  dashboard: {
    title: 'Dashboard'
  },
  account: {
    profile: 'My Profile'
  },
  login: 'Login',
  module_configurator: {
    title: 'Module Configurator'
  },
  manage_purchase: {
    title: 'Manage Purchase'
  },
  user_management: {
    title: 'User Management',
    users: {
      title: 'Users',
      system_permission: 'System Permission'
    }
  },
  opportunity: {
    title: 'Opportunity',
    business_leads: {
      title: 'Business Leads'
    }
  },
  purchase_target: {
    title: 'Manage Purchase',
    list: {
      title: 'Purchase Target'
    },
    add_new: {
      title: 'Add New Purchase Target'
    },
    detail: {
      title: 'Detail'
    }
  },
  purchase_tracking: {
    title: 'Manage Purchase',
    list: {
      title: 'Purchase Tracking'
    },
    detail: {
      title: 'Detail'
    }
  },
  supplier_management: {
    title: 'Supplier Management',
    list: {
      title: 'list'
    },
    detail: {
      title: 'Detail'
    }
  },
  manage_logistics: {
    title: 'Manage Logistics'
  },
  warehouse_entrance: {
    title: 'Manage Logistics',
    list: {
      title: 'Warehouse Entrance'
    },
    detail: {
      title: 'Detail'
    }
  },
  manage_invoice: {
    title: 'Manage Invoice'
  },
  purchase_invoice: {
    title: 'Manage Invoice',
    list: {
      title: 'Purchase Invoice'
    },
    detail: {
      title: 'Detail'
    }
  },
  sass_purchase_invoice: {
    title: 'Manage Invoice',
    list: {
      title: 'SaaS Purchase Invoice'
    },
    detail: {
      title: 'Detail'
    }
  },
  upload_invoice: {
    title: 'Manage Invoice',
    list: {
      title: 'Upload Invoice'
    },
    detail: {
      title: 'Detail'
    }
  },
  saas_purchase_invoice_file: {
    title: 'Manage Invoice',
    list: {
      title: 'SaaS Purchase Invoice File'
    },
    detail: {
      title: 'Detail'
    }
  },
  manage_warehouse: {
    title: 'Manage Warehouse'
  },
  warehouse_inventory: {
    title: 'Manage Warehouse',
    list: {
      title: 'Warehouse Inventory'
    },
    detail: {
      title: 'Detail'
    }
  },
  article_review: {
    title: 'Manage Logistics',
    list: {
      title: 'Article Management'
    },
    detail: {
      title: 'Detail'
    }
  },
  purchase_summary: {
    title: 'Manage Purchase',
    list: {
      title: 'Purchase Summary'
    },
    detail: {
      title: 'Detail'
    }
  }
};
